'use client'

import themeVariables from "@/config/theme/themeVariable";
import { mixpanelTrackPageView } from "@/utils/mixpanel";
import { Button } from "@mantine/core";
import Link from "next/link";
import { useEffect } from "react";

export default function NotFoundPage() {

  useEffect(() => {
    mixpanelTrackPageView('NotFoundPage');
  }, [])

  return (
    <div className="mx-auto px-4 flex items-center justify-start pt-32">
      <div className="max-w-lg m-auto space-y-3 text-center">
        <h3 className="text-blue-500 font-semibold">
            404
        </h3>
        <p className="text-gray-800 text-4xl font-bold sm:text-5xl tracking-tight">
          존재하지 않는 페이지
        </p>
        <p className="text-gray-600">
          잘못된 경로로 들어왔어요. 페이지 URL을 다시 한번 확인해주세요.
        </p>
        <div className="flex flex-wrap items-center justify-center gap-3 pt-6">
          <Link href={'/'}>
            <Button classNames={themeVariables.button.dark}>
              홈으로 돌아가기
            </Button>
          </Link>
        </div>
      </div>
    </div>
  )
}